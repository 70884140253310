<template>
  <div>
    <div v-if="!isConnected">
      <trac-loading v-if="loading" />
      <div class="mb-2 mx-12 mt-12">
        <div
          @click="$router.go(-1)"
          class="mb-1 cursor-pointer text-sm font-light"
        >
          &lt; Back
        </div>
        <h1 class="font-bold">Login</h1>
      </div>
      <div class="md:px-12 px-6 py-8 m-12 border rounded bg-white">
        <h1 class="font-bold">Enter your noona details below to login</h1>
        <div class="my-12 mx-auto md:w-3/5 w-full">
          <trac-input
            v-model="emailField"
            data-test="noona-email-login"
            placeholder="Email"
            class="mt-4"
          />
          <trac-input
            v-model="passwordField"
            data-test="noona-password-login"
            placeholder="Password"
            class="mt-4"
            type="password"
          />
          <div class="text-left mt-6">
            <trac-button
              @button-clicked="doLogin()"
              data-test="noona-button-login"
              class="mr-5 px-12 w-full md:w-auto"
            >
              <div class="leading-7">LOGIN</div>
            </trac-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "../../eventBus.js";
import redirectMixin from "./redirectMixin"
export default {
  mixins: [redirectMixin],
  data() {
    return {
      loading: true,
      noonaData: null,
      message: null,
      emailField: "",
      passwordField: "",
      business_id: GET_USER_BUSINESS_ID(),
    };
  },
  created() {
    this.loading = true;
    this.checkStatus();
  },
  computed: {
    isConnected() {
      const defaultCheck = {
        status: false,
        data: { connected: false, noona_business: null },
      };
      const connectStatus = this.noonaData || defaultCheck;
      return connectStatus.status && connectStatus.data.connected;
    },
  },
  methods: {
    recheck() {},
    checkStatus() {
      this.$store.dispatch("NOONA_CHECK_STATUS").then((data) => {
          if(data.status) {
            console.log("data resolved: ", data);
            this.noonaData = data;
            this.loading = false;
            this.checkToProceed(data.data.noona_business || []);
          } else {
            this.loading = false
            eventBus.$emit("trac-alert", {
              message: data.message,
            });
          }
        })
        .catch((err) => {
          console.error('Noona Login Resolution Error: ', err);
          (this.loading = false),
            (this.message = "An error occured. Please try again");
        });
    },
    doLogin() {
      const productionPayload = {
        business_id: this.business_id,
        username: this.emailField,
        password: this.passwordField,
      };
      this.loading = true;
      this.$store.dispatch("NOONA_LOGIN", productionPayload).then((data) => {
        if (data.status) {
          this.loading = false;
          this.noonaData = data;
          eventBus.$emit("trac-alert", {
            message: data.message,
          });
          this.$TrackEvent('TA_MERCHANT_APP_NOONA_LOGIN')
          this.$router.push({ name: "Appointments" });
          // const businessList = data || []
          // this.checkToProceed(businessList);
        } else {
          this.loading = false;
          // eventBus.$emit("trac-alert", {
          //   message: data.message,
          // });
        }
      });
    },
    checkToProceed(noonaBusinesses = []) {
      this.redirectCheck(noonaBusinesses, true)
    },
  },
};
</script>

<style lang="postcss" scoped>
</style>